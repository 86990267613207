import TeaserOpeningHours from './TeaserOpeningHours.js'

const teaserOpeningHoursSelector = '.js-teaser-opening-hours'

export function getOpeningHours (elements) {
  const openingData = TeaserOpeningHours.getOpeningData(elements)
  if (openingData && openingData.length > 0) {
    updateTeasers(openingData)
  }
}

function updateTeasers (teasersData) {
  teasersData.forEach((teaserData) => {
    const teaserElements = document.querySelectorAll(teaserOpeningHoursSelector + '[data-globalid="' + teaserData['globalId'] + '"]')
    if (teaserElements) {
      teaserElements.forEach((teaserElem) => {
        if (!(teaserElem.parentElement.classList.contains('has-opening-hours'))) {
          teaserElem.parentElement.classList.add('has-opening-hours')
          let targetElem = teaserElem.parentElement
          if (teaserElem.dataset.targetSelector && targetElem.querySelector(teaserElem.dataset.targetSelector)) {
            targetElem = targetElem.querySelector(teaserElem.dataset.targetSelector)
          }
          targetElem.innerHTML = targetElem.innerHTML + teaserData['html']
          if (teaserElem.parentElement) {
            const teaserIconElems = teaserElem.parentElement.querySelectorAll('svg[data-url]')
            if (teaserIconElems.length) {
              teaserElem.parentElement.querySelectorAll('svg[data-url]').forEach((iconElem) => {
                if (teaserData['html'].length > 0) {
                  iconElem.parentElement.innerHTML = '<svg class="icon__svg" data-url="' + teaserElem.dataset.timeIcon + '"></svg>'
                }
                const iconUrl = (teaserData['html'].length > 0) ? teaserElem.dataset.timeIcon : iconElem.dataset.url
                fetchSVG(iconUrl, iconElem)
              })
            } else {
              teaserElem.parentElement.querySelectorAll('.icon').forEach(iconElem => {
                if (teaserData['html'].length > 0) {
                  iconElem.innerHTML = '<svg class="icon__svg" data-url="' + teaserElem.dataset.timeIcon + '"></svg>'
                  fetchSVG(teaserElem.dataset.timeIcon, iconElem)
                }
              })
            }
          }
        }
        teaserElem.classList.remove('hidden')
      })
    }
  })
}

if (document.querySelector(teaserOpeningHoursSelector) !== null) {
  getOpeningHours(document.querySelectorAll(teaserOpeningHoursSelector))
}
